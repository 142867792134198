<template>
  <b-row>
    <b-col cols="12" class="mb-32">
      <b-row align-h="between" class="mt-n32">
        <b-col class="hp-flex-none w-auto mt-32">
          <breadcrumb v-if="project" :items="breadcrumb" :activeTitle="project.name" />
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12" class="mb-32">
      <b-row
        class="hp-profile-mobile-menu-btn bg-black-0 hp-bg-color-dark-100 rounded py-12 px-8 px-sm-12 mb-16 mx-0"
      >
        <div class="d-inline-block">
          <b-button variant="none" class="border-0 btn-icon-only" v-b-toggle.sidebar-profile>
            <i
              class="ri-menu-fill hp-text-color-black-80 hp-text-color-dark-30 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>
      </b-row>

      <b-row class="bg-black-0 hp-bg-color-dark-100 rounded pr-16 pr-sm-32 mx-0">
        <sidebar-show />

        <b-col class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden">
          <project-information v-if="$route.name == 'show-project-information'" />

          <project-places v-if="$route.name == 'show-project-places'" />

          <project-checklists v-if="$route.name == 'show-project-checklists'" />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Breadcrumb from '@/layouts/components/content/breadcrumb/Breadcrumb.vue'
import SidebarShow from './SidebarShow.vue'
import ProjectInformation from './ProjectInformation.vue'
import ProjectPlaces from '../../places/Index.vue'
import ProjectChecklists from '../checklists/Index.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      routeName: '',
      breadcrumb: [
        {
          path: '/app/projects',
          text: 'Proyectos'
        }
      ]
    }
  },
  components: {
    Breadcrumb,
    SidebarShow,
    ProjectInformation,
    ProjectPlaces,
    ProjectChecklists
  },
  mounted() {
    this.getProject(this.$route.params.id)
  },
  methods: {
    ...mapActions({
      getProject: 'project/getProject'
    })
  },
  computed: {
    ...mapGetters({
      project: 'project/project'
    }),
    currentRouteName() {
      return this.$route.name
    }
  }
}
</script>
