<template>
  <b-modal id="modal-project" hide-footer header-class="align-items-center">
    <template #modal-header="{ close }">
      <h5 class="mb-0">{{ form.id ? 'Actualizar proyecto' : 'Crear proyecto' }}</h5>
      <b-button
        variant="text"
        @click="close()"
        class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
        style="min-height: 24px"
      >
        <i class="ri-close-line hp-text-color-dark-0 lh-1" style="font-size: 24px"></i>
      </b-button>
    </template>

    <b-form class="mb-8">
      <b-form-group label="Nombre del proyecto :" label-for="projectName" class="mb-16">
        <b-form-input
          v-model="form.name"
          id="projectName"
          :class="{ 'is-invalid': $v.form.name.$error }"
          type="text"
        ></b-form-input>
        <div v-if="$v.form.name.$error" class="invalid-feedback">
          <span v-if="!$v.form.name.required">Nombre del proyecto es requerido.</span>
        </div>
      </b-form-group>

      <b-form-group label="Código del proyecto :" label-for="projectCode" class="mb-16">
        <b-form-input
          v-model="form.code"
          id="projectCode"
          :class="{ 'is-invalid': $v.form.code.$error }"
          type="text"
        ></b-form-input>
        <div v-if="$v.form.code.$error" class="invalid-feedback">
          <span v-if="!$v.form.code.required">Código del proyecto es requerido.</span>
        </div>
      </b-form-group>

      <b-form-group label="Tipo de proyecto :" label-for="projectType" class="mb-16">
        <b-form-select
          v-model="form.project_type"
          :options="wrappedTypesProject"
          id="projectType"
          class="mb-1"
          :class="{ 'is-invalid': $v.form.project_type.$error }"
          value-field="id"
          text-field="name"
        ></b-form-select>
        <div v-if="$v.form.project_type.$error" class="invalid-feedback">
          <span v-if="!$v.form.project_type.required">Tipo del proyecto es requerido.</span>
        </div>
      </b-form-group>

      <b-form-group
        label="Descripción del proyecto :"
        label-for="projectDescription"
        class="mb-16"
      >
        <b-form-textarea
          v-model="form.description"
          id="projectDescription"
          :class="{ 'is-invalid': $v.form.description.$error }"
        ></b-form-textarea>
        <div v-if="$v.form.description.$error" class="invalid-feedback">
          <span v-if="!$v.form.description.required"
            >Descripción del proyecto es requerido.</span
          >
        </div>
      </b-form-group>

      <b-form-group
        label="Cargar imagen del proyecto (Opcional)"
        label-for="file-project"
        class="mb-16"
      >
        <input
          id="file-project"
          @change="setImageProject"
          type="file"
          placeholder="Seleccionar imagen"
          accept=".jpg, .png, .jpeg"
        />
      </b-form-group>
    </b-form>

    <div class="d-flex justify-content-end flex-wrap mt-32">
      <b-button variant="outline-info-3" @click="$bvModal.hide('modal-project')">
        Cerrar
      </b-button>
      <b-button type="submit" class="ml-16" variant="outline-primary" @click="handleSubmit">
        <b-spinner v-if="loading" small type="grow" class="mr-8"></b-spinner>
        {{ form.id ? 'Actualizar proyecto' : 'Crear proyecto' }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
const { required } = require('vuelidate/lib/validators')

export default {
  mixins: [validationMixin],
  mounted() {
    this.getTypesProject()
  },
  data() {
    return {
      loading: false,
      form: {
        id: null,
        name: '',
        code: '',
        description: '',
        image: null,
        project_type: null
      }
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      code: {
        required
      },
      description: {
        required
      },
      project_type: {
        required
      }
    }
  },
  methods: {
    ...mapActions({
      updateOrCreateProject: 'project/updateOrCreateProject',
      getTypesProject: 'project/getTypesProject'
    }),
    show(project) {
      this.$nextTick(() => {
        this.$v.$reset()
      })
      if (project) {
        this.form = {
          id: project.id,
          name: project.name,
          code: project.code,
          description: project.description,
          project_type: project.project_type ? project.project_type.id : null
        }
      }
      this.$bvModal.show('modal-project')
    },
    setImageProject(e) {
      if (e.target.files.length) {
        this.form.image = e.target.files[0]
      }
    },
    async handleSubmit() {
      this.$v.$touch()
      this.$v.form.$touch()
      if (!this.$v.form.$anyError) {
        this.loading = true
        const { id, ...rest } = this.form
        let response
        if (id) {
          response = await this.updateOrCreateProject({
            id,
            ...rest
          })
        } else {
          response = await this.updateOrCreateProject({ ...rest, status: 1 })
        }
        this.loading = false
        if (response.status == 201 || response.status == 200) {
          this.$bvToast.toast(`Proyecto ${id ? 'actualizado' : 'creado'} correctamente`, {
            title: `Excelente`,
            variant: 'primary',
            solid: false
          })
          this.closeModal()
          this.$emit('success')
        }
      }
    },
    closeModal() {
      this.$bvModal.hide('modal-project')
      this.form = {
        id: null,
        name: '',
        code: '',
        description: '',
        image: null,
        project_type: null
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    }
  },
  computed: {
    ...mapGetters({
      typesProject: 'project/typesProject'
    }),
    wrappedTypesProject() {
      return [{ id: null, name: 'Selecciona un tipo' }, ...this.typesProject]
    }
  }
}
</script>

<style></style>
