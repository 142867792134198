<template>
  <b-col class="hp-profile-menu py-24 px-0" style="flex: 0 0 240px">
    <div class="w-100" v-if="project">
      <div class="hp-profile-menu-header mt-16 mt-lg-0 text-center">
        <div class="d-flex justify-content-center">
          <div class="d-inline-block position-relative">
            <img
              width="160px"
              style="border-radius: 0.6rem"
              height="155px"
              :src="
                project.image
                  ? project.image
                  : require('@/assets/img/app/projects/solar-panel.png')
              "
            />
          </div>
        </div>

        <h5 class="mt-24 mb-4">{{ project.name }}</h5>
        <a class="hp-p1-body">
          {{ project.business_unit ? project.business_unit : "-" }}
          <br />
          {{ project.project_type.name }}
          <br />
          {{ project.code }}
        </a>
      </div>
    </div>

    <div
      class="hp-profile-menu-body w-100 text-left mt-48 mt-lg-0"
      v-if="project"
    >
      <ul>
        <li class="mt-4 mb-16">
          <b-link
            :to="{
              name: 'show-project-information',
              params: {
                id: project.id,
              },
            }"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-info-3 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              path == `/app/projects/${project.id}/information` ? ' active' : ''
            }`"
          >
            <i class="iconly-Curved-Document mr-8"></i>
            <span>Información</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-success hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>

        <li class="mt-4 mb-16">
          <b-link
            :to="{
              name: 'show-project-places',
              params: {
                id: project.id,
              },
            }"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              path == `/app/projects/${project.id}/places` ? ' active' : ''
            }`"
          >
            <i class="iconly-Curved-Activity mr-8"></i>
            <span>Puntos de instalación</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-success hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>
        <li class="mt-4 mb-16">
          <b-link
            :to="{
              name: 'show-project-checklists',
              params: {
                id: project.id,
              },
            }"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              path == `/app/projects/${project.id}/checklists` ? ' active' : ''
            }`"
          >
            <i class="iconly-Curved-Folder mr-8"></i>
            <span>Plantillas</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-success hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>
        <li class="mt-4 mb-16">
          <b-link
            :to="{
              name: 'projects',
            }"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center`"
          >
            <i class="iconly-Curved-ArrowLeft mr-8"></i>
            <span>Volver</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-success hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>
      </ul>
    </div>
    <div class="hp-profile-menu-footer">Proyectos</div>
  </b-col>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      path: "",
    };
  },
  watch: {
    $route() {
      this.getRoute();
    },
  },
  methods: {
    getRoute() {
      this.path = this.$route.path;
    },
  },
  created() {
    this.getRoute();
  },
  computed: {
    ...mapGetters({
      project: "project/project",
    }),
  },
};
</script>
