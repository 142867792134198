var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32 mt-16",attrs:{"cols":"12"}},[_c('b-row',{staticClass:"mt-n32",attrs:{"align-h":"between"}},[_c('b-col',{staticClass:"hp-flex-none w-auto mt-32"},[_c('breadcrumb',{attrs:{"activeTitle":"Plantillas"}})],1),_c('b-col',{staticClass:"hp-flex-none w-auto mt-24"},[_c('b-row',[_c('b-col',{staticClass:"hp-flex-none w-auto pr-0"},[(
                _vm.currentUser &&
                _vm.checkIfHaveRole(_vm.currentUser.rol, [
                  _vm.ROLES.coordinator,
                  _vm.ROLES.superadmin,
                ])
              )?_c('b-button',{staticClass:"btn-ghost",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.editChecklist.openModal()}}},[_vm._v("Agregar Plantilla")]):_vm._e()],1)],1)],1)],1)],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-0"},[_vm._v("Plantillas")]),_c('h5',{staticClass:"mb-0 text-black-80 hp-text-color-dark-30"},[_vm._v(" Listado de plantillas del proyecto ")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('datatable',{attrs:{"items":_vm.projectChecklists,"fields":_vm.fields},scopedSlots:_vm._u([{key:"checklist_type_show",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(item.checklist.checklist_type ? item.checklist.checklist_type.name : "Sin tipo")+" ")])]}},{key:"actions",fn:function({ item }){return [_c('div',{},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-ghost",attrs:{"size":"sm","title":"Eliminar plantilla","variant":"danger"},on:{"click":function($event){return _vm.deleteProjectChecklist(item)}}},[_c('i',{staticClass:"iconly-Curved-Delete"})])],1)]}}])})],1),_c('update-or-create-project-checklist',{ref:"editChecklist",on:{"success":function($event){return _vm.getProjectChecklists({
        project_id: _vm.$route.params.id,
      })}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }