<template>
  <b-row>
    <b-col cols="12" class="mb-32 mt-16">
      <b-row align-h="between" class="mt-n32">
        <b-col class="hp-flex-none w-auto mt-32">
          <breadcrumb activeTitle="Plantillas" />
        </b-col>
        <b-col class="hp-flex-none w-auto mt-24">
          <b-row>
            <b-col class="hp-flex-none w-auto pr-0">
              <b-button
                v-if="
                  currentUser &&
                  checkIfHaveRole(currentUser.rol, [
                    ROLES.coordinator,
                    ROLES.superadmin,
                  ])
                "
                @click="$refs.editChecklist.openModal()"
                variant="primary"
                class="btn-ghost"
                >Agregar Plantilla</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12" class="mb-32">
      <h2 class="mb-0">Plantillas</h2>
      <h5 class="mb-0 text-black-80 hp-text-color-dark-30">
        Listado de plantillas del proyecto
      </h5>
    </b-col>

    <b-col cols="12">
      <datatable :items="projectChecklists" :fields="fields">
        <template #checklist_type_show="{ item }">
          <span>
            {{
              item.checklist.checklist_type
                ? item.checklist.checklist_type.name
                : "Sin tipo"
            }}
          </span>
        </template>
        <template #actions="{ item }">
          <div class="">
            <!-- <b-button
              size="sm"
              v-b-tooltip.hover
              title="Responder plantilla"
              variant="primary"
              @click="goToQuestionsChecklist(item)"
              class="btn-ghost mx-2"
            >
              <i class="iconly-Light-Show"></i>
            </b-button> -->
            <b-button
              size="sm"
              v-b-tooltip.hover
              title="Eliminar plantilla"
              variant="danger"
              @click="deleteProjectChecklist(item)"
              class="btn-ghost"
            >
              <i class="iconly-Curved-Delete"></i>
            </b-button>
          </div>
        </template>
      </datatable>
    </b-col>

    <update-or-create-project-checklist
      ref="editChecklist"
      @success="
        getProjectChecklists({
          project_id: $route.params.id,
        })
      "
    ></update-or-create-project-checklist>
  </b-row>
</template>

<script>
import Breadcrumb from "@/layouts/components/content/breadcrumb/Breadcrumb.vue";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import { mapActions, mapGetters } from "vuex";
import UpdateOrCreateProjectChecklist from "./updateOrCreateProjectChecklist.vue";
import Datatable from "@/view/components/common/datatable/Datatable.vue";
import { verifyRole } from "@/mixins/role";

export default {
  mixins: [verifyRole],
  components: {
    Breadcrumb,
    PageTitle,
    UpdateOrCreateProjectChecklist,
    Datatable,
  },
  mounted() {
    this.getProjectChecklists({
      project_id: this.$route.params.id,
    });
  },
  data() {
    return {
      fields: [
        {
          key: "checklist.name",
          label: "Nombre",
          sortable: true,
          class: "text-center",
        },
        {
          key: "checklist_type_show",
          label: "Tipo",
          sortable: true,
          class: "text-center",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getProjectChecklists: "project/getProjectChecklists",
      sendDeleteProjectChecklist: "project/deleteProjectChecklist",
    }),
    goToQuestionsChecklist(item) {
      this.$router.push({
        name: "project-checklist",
        params: {
          id: this.$route.params.id,
          checklistId: item.checklist.id,
        },
      });
    },
    deleteProjectChecklist(item) {
      this.$bvModal
        .msgBoxConfirm("Esta acción no se puede revertir", {
          title: "¿Estas seguro de eliminar esta plantilla?.",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-info-3",

          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.sendDeleteProjectChecklist(item.id);
            if (resp.status == 204) {
              this.$bvToast.toast(
                `Plantilla de Proyecto eliminada correctamente`,
                {
                  title: `Excelente`,
                  variant: "primary",
                  solid: false,
                }
              );
            }
            this.getProjectChecklists({
              project_id: this.$route.params.id,
            });
          }
        })
        .catch((err) => {
          return err;
        });
    },
  },
  computed: {
    ...mapGetters({
      projectChecklists: "project/projectChecklists",
      currentUser: "auth/currentUser",
    }),
    wrappedFields() {
      if (
        this.currentUser &&
        this.checkIfHaveRole(this.currentUser.rol, [
          this.ROLES.coordinator,
          this.ROLES.superadmin,
        ])
      ) {
        return [...this.fields, { key: "actions", label: "Acciones" }];
      }
      return this.fields;
    },
  },
};
</script>
