<template>
  <b-modal id="modal-place" hide-footer header-class="align-items-center">
    <template #modal-header="{ close }">
      <h5 class="mb-0">
        {{ form.id ? 'Actualizar punto de instalación' : 'Crear punto de instalación' }}
      </h5>
      <b-button
        variant="text"
        @click="close()"
        class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
        style="min-height: 24px"
      >
        <i class="ri-close-line hp-text-color-dark-0 lh-1" style="font-size: 24px"></i>
      </b-button>
    </template>

    <b-form class="mb-8">
      <b-form-group
        label="Nombre del punto de instalación :"
        label-for="placeName"
        class="mb-16"
      >
        <b-form-input
          v-model="form.name"
          id="placeName"
          placeholder="Nombre del punto de instalación :"
          :class="{ 'is-invalid': $v.form.name.$error }"
          type="text"
        ></b-form-input>
        <div v-if="$v.form.name.$error" class="invalid-feedback">
          <span v-if="!$v.form.name.required"
            >Nombre del punto de instalación es requerido.</span
          >
        </div>
      </b-form-group>

      <b-form-group
        label="Código del punto de instalación :"
        label-for="placeCode"
        class="mb-16"
      >
        <b-form-input
          v-model="form.code"
          id="placeCode"
          placeholder="Código del punto de instalación :"
          :class="{ 'is-invalid': $v.form.code.$error }"
          type="text"
        ></b-form-input>
        <div v-if="$v.form.code.$error" class="invalid-feedback">
          <span v-if="!$v.form.code.required"
            >Código del punto de instalación es requerido.</span
          >
        </div>
      </b-form-group>

      <b-form-group label="Comuna :" label-for="placeCommune" class="mb-16">
        <v-select
          id="placeCommune"
          label="name"
          :reduce="(commune) => commune.id"
          v-model="form.commune_id"
          :options="wrappedCommunes"
          :class="{ 'is-invalid': $v.form.commune_id.$error }"
          placeholder="Selecciona una comuna"
        >
          <template #no-options="{ search, searching, loading }"> Sin resultados. </template>
        </v-select>
        <div v-if="$v.form.commune_id.$error" class="invalid-feedback">
          <span v-if="!$v.form.commune_id.required">la comuna es requerido.</span>
        </div>
      </b-form-group>

      <b-form-group
        label="Latitud del punto de instalación :"
        label-for="placeLat"
        class="mb-16"
      >
        <b-form-input
          placeholder="Latitud del punto de instalación :"
          v-model="form.lat"
          :class="{ 'is-invalid': $v.form.lat.$error }"
          id="placeLat"
          type="number"
        ></b-form-input>
        <div v-if="$v.form.lat.$error" class="invalid-feedback">
          <span v-if="!$v.form.lat.required"
            >Latitud del punto de instalación es requerida.</span
          >
        </div>
      </b-form-group>

      <b-form-group
        label="Longitud del punto de instalación :"
        label-for="placeLon"
        class="mb-16"
      >
        <b-form-input
          placeholder="Longitud del punto de instalación :"
          v-model="form.lon"
          :class="{ 'is-invalid': $v.form.lon.$error }"
          id="placeLon"
          type="number"
        ></b-form-input>
        <div v-if="$v.form.lat.$error" class="invalid-feedback">
          <span v-if="!$v.form.lat.required"
            >Longitud del punto de instalación es requerida.</span
          >
        </div>
      </b-form-group>

      <b-form-group
        label="Dirección del punto de instalación :"
        label-for="placeAddress"
        class="mb-16"
      >
        <b-form-textarea
          placeholder="Dirección del punto de instalación :"
          v-model="form.address"
          id="placeAddress"
        ></b-form-textarea>
      </b-form-group>
    </b-form>

    <div class="d-flex justify-content-end flex-wrap mt-32">
      <b-button variant="outline-info-3" @click="$bvModal.hide('modal-place')">
        Cerrar
      </b-button>
      <b-button type="submit" class="ml-16" variant="outline-primary" @click="handleSubmit">
        <b-spinner v-if="loading" small type="grow" class="mr-8"></b-spinner>
        {{ form.id ? 'Actualizar punto de instalación' : 'Crear punto de instalación' }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
const { required } = require('vuelidate/lib/validators')

export default {
  mixins: [validationMixin],
  mounted() {
    this.getCommunes()
  },
  data() {
    return {
      loading: false,
      project_id: null,
      form: {
        id: null,
        name: '',
        code: '',
        address: '',
        lat: '',
        lon: '',
        commune_id: null
      }
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      code: {
        required
      },
      lat: {
        required
      },
      lon: {
        required
      },
      commune_id: {
        required
      }
    }
  },
  methods: {
    ...mapActions({
      updateOrCreatePlace: 'place/updateOrCreatePlace',
      getCommunes: 'tools/getCommunes'
    }),
    show(place) {
      this.$nextTick(() => {
        this.$v.$reset()
      })
      if (place) {
        this.form = {
          id: place.id,
          name: place.name,
          code: place.code,
          address: place.address,
          lat: place.lat,
          lon: place.lon,
          commune_id: place.commune ? place.commune.id : null
        }
        this.project_id = place.project.id
      }
      this.$bvModal.show('modal-place')
    },
    async handleSubmit() {
      this.$v.$touch()
      this.$v.form.$touch()
      if (!this.$v.form.$anyError) {
        this.loading = true
        const { id, ...rest } = this.form
        let response
        if (id) {
          response = await this.updateOrCreatePlace({
            id,
            project: this.project_id,
            commune: this.form.commune_id,
            ...rest
          })
        } else {
          response = await this.updateOrCreatePlace({
            ...rest,
            commune: this.form.commune_id,
            project: this.$route.params.id
          })
        }
        this.loading = false
        if (response.status == 201 || response.status == 200) {
          this.$bvToast.toast(
            `Punto de instalación ${id ? 'actualizado' : 'creado'} correctamente`,
            {
              title: `Excelente`,
              variant: 'primary',
              solid: false
            }
          )
          this.closeModal()
          this.$emit('success')
        }
      }
    },
    closeModal() {
      this.$bvModal.hide('modal-place')
      this.form = {
        id: null,
        name: '',
        code: '',
        address: '',
        lat: '',
        lon: '',
        commune_id: null
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    }
  },
  computed: {
    ...mapGetters({
      communes: 'tools/communes'
    }),
    wrappedCommunes() {
      return [{ id: null, name: 'Selecciona una comuna' }, ...this.communes]
    }
  }
}
</script>

<style></style>
