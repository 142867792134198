<template>
  <b-modal id="modal-project-checklist" hide-footer header-class="align-items-center">
    <template #modal-header="{ close }">
      <h5 class="mb-0">Agregar Plantilla</h5>
      <b-button
        variant="text"
        @click="closeModal"
        class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
        style="min-height: 24px"
      >
        <i class="ri-close-line hp-text-color-dark-0 lh-1" style="font-size: 24px"></i>
      </b-button>
    </template>

    <b-form class="mb-8">
      <b-form-group label="Plantilla :" label-for="checklistProject" class="mb-16">
        <b-form-select
          v-model="form.checklist"
          :options="wrappedChecklists"
          id="checklistProject"
          class="mb-1"
          value-field="id"
          :class="{ 'is-invalid': $v.form.checklist.$error }"
          text-field="name"
        ></b-form-select>
        <div v-if="$v.form.checklist.$error" class="invalid-feedback">
          <span v-if="!$v.form.checklist.required">Tipo de plantilla es requerida.</span>
        </div>
      </b-form-group>
    </b-form>

    <div class="d-flex justify-content-end flex-wrap mt-32">
      <b-button variant="outline-info-3" @click="closeModal"> Cerrar </b-button>
      <b-button type="submit" class="ml-16" variant="outline-primary" @click="handleSubmit">
        <b-spinner v-if="loading" small type="grow" class="mr-8"></b-spinner>
        Agregar plantilla
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
const { required } = require('vuelidate/lib/validators')

export default {
  mixins: [validationMixin],
  mounted() {
    this.getChecklists()
  },
  data() {
    return {
      loading: false,
      form: {
        checklist: null
      }
    }
  },
  validations: {
    form: {
      checklist: {
        required
      }
    }
  },
  methods: {
    ...mapActions({
      createProjectChecklist: 'project/createProjectChecklist',
      getChecklists: 'checklist/getChecklists'
    }),
    async handleSubmit() {
      this.$v.$touch()
      this.$v.form.$touch()
      if (!this.$v.form.$anyError) {
        this.loading = true
        const { ...rest } = this.form
        let response
        response = await this.createProjectChecklist({
          project: this.$route.params.id,
          ...rest
        })
        this.loading = false
        if (response.status == 201) {
          this.$bvToast.toast(`Plantilla agregada correctamente`, {
            title: `Excelente`,
            variant: 'primary',
            solid: false
          })
          this.closeModal()
          this.$emit('success')
        }
      }
    },
    openModal() {
      this.$nextTick(() => {
        this.$v.$reset()
      })
      this.$bvModal.show('modal-project-checklist')
    },
    closeModal() {
      this.$bvModal.hide('modal-project-checklist')
      this.form = {
        checklist: null
      }
    }
  },
  computed: {
    ...mapGetters({
      checklists: 'checklist/checklists'
    }),
    wrappedChecklists() {
      return [{ id: null, name: 'Selecciona una plantilla' }, ...this.checklists]
    }
  }
}
</script>

<style></style>
