<template>
  <b-row>
    <b-col cols="12">
      <b-row align-v="center" align-h="between">
        <b-col cols="12" md="6">
          <h3>Información</h3>
        </b-col>
        <b-col
          cols="12"
          class="hp-profile-content-list mt-8 pb-8"
          v-if="project"
        >
          <ul>
            <li>
              <span class="hp-p1-body">Nombre</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{ project.name }}
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Código</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{ project.code }}
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Tipo de proyecto</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{ project.project_type.name }}
              </span>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12" md="8" v-if="project">
      <h3>Descripción</h3>
      <p class="hp-p1-body mb-0" v-html="project.description"></p>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col
      cols="12"
      md="8"
      v-if="
        project &&
        currentUser &&
        checkIfHaveRole(currentUser.rol, [ROLES.superadmin])
      "
    >
      <h3>Gestión</h3>
      <div class="d-flex align-items-center">
        <b-button
          variant="warning"
          class="btn-ghost"
          @click="$refs.editProject.show(project)"
          >Editar Proyecto</b-button
        >
        <b-button variant="danger" @click="deleteProject" class="mx-2 btn-ghost"
          >Eliminar Proyecto</b-button
        >
      </div>
    </b-col>

    <update-or-create-project
      @success="getProject($route.params.id)"
      ref="editProject"
    ></update-or-create-project>
  </b-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UpdateOrCreateProject from "../updateOrCreateProject.vue";
import { verifyRole } from "@/mixins/role";

export default {
  mixins: [verifyRole],
  components: {
    UpdateOrCreateProject,
  },
  computed: {
    ...mapGetters({
      project: "project/project",
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    ...mapActions({
      getProject: "project/getProject",
      sendDeleteProject: "project/deleteProject",
    }),
    deleteProject() {
      this.$bvModal
        .msgBoxConfirm("Esta acción no se puede revertir", {
          title: "¿Estas seguro de eliminar este proyecto?.",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-info-3",

          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.sendDeleteProject(this.project.id);
            if (resp.status == 204) {
              this.$bvToast.toast(`Proyecto eliminado correctamente`, {
                title: `Excelente`,
                variant: "primary",
                solid: false,
              });
              setTimeout(() => {
                this.$router.push({
                  name: "projects",
                });
              }, 1500);
            }
          }
        })
        .catch((err) => {
          return err;
        });
    },
  },
};
</script>
